function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

jQuery(function () {
  initAccordion();
}); // accordion menu init

function initAccordion() {
  jQuery('.program__hold').slideAccordion({
    opener: '.program__list-opener',
    slider: '.program__list-slide',
    animSpeed: 300
  });
  jQuery('.accordion__list').slideAccordion({
    opener: '.accordion__list-opener',
    slider: '.accordion__list-slide',
    animSpeed: 300
  });
  jQuery('.about__accordion-list').slideAccordion({
    opener: '>a',
    slider: '>.about__accordion-slide',
    animSpeed: 300
  });
  ResponsiveHelper.addRange({
    '..1199': {
      on: function on() {
        jQuery('.navbar__nav').slideAccordion({
          opener: '>a',
          slider: '.sub-menu',
          animSpeed: 300
        });
      },
      off: function off() {
        jQuery('.navbar__nav').slideAccordion('destroy');
      }
    }
  });
}
/*
 * jQuery Accordion plugin new
 */


;

(function (root, factory) {
  'use strict';

  if (typeof define === 'function' && define.amd) {
    define(['jquery'], factory);
  } else if ((typeof exports === "undefined" ? "undefined" : _typeof(exports)) === 'object') {
    module.exports = factory(require('jquery'));
  } else {
    root.SlideAccordion = factory(jQuery);
  }
})(window, function ($) {
  'use strict';

  var accHiddenClass = 'js-acc-hidden';

  function SlideAccordion(options) {
    this.options = $.extend(true, {
      allowClickWhenExpanded: false,
      activeClass: 'active',
      opener: '.opener',
      slider: '.slide',
      animSpeed: 300,
      collapsible: true,
      event: 'click',
      scrollToActiveItem: {
        enable: false,
        breakpoint: 767,
        // max-width
        animSpeed: 600,
        extraOffset: null
      }
    }, options);
    this.init();
  }

  SlideAccordion.prototype = {
    init: function init() {
      if (this.options.holder) {
        this.findElements();
        this.setStateOnInit();
        this.attachEvents();
        this.makeCallback('onInit');
      }
    },
    findElements: function findElements() {
      this.$holder = $(this.options.holder).data('SlideAccordion', this);
      this.$items = this.$holder.find(':has(' + this.options.slider + ')');
    },
    setStateOnInit: function setStateOnInit() {
      var self = this;
      this.$items.each(function () {
        if (!$(this).hasClass(self.options.activeClass)) {
          $(this).find(self.options.slider).addClass(accHiddenClass);
        }
      });
    },
    attachEvents: function attachEvents() {
      var self = this;

      this.accordionToggle = function (e) {
        var $item = jQuery(this).closest(self.$items);
        var $actiItem = self.getActiveItem($item);

        if (!self.options.allowClickWhenExpanded || !$item.hasClass(self.options.activeClass)) {
          e.preventDefault();
          self.toggle($item, $actiItem);
        }
      };

      this.$items.on(this.options.event, this.options.opener, this.accordionToggle);
    },
    toggle: function toggle($item, $prevItem) {
      if (!$item.hasClass(this.options.activeClass)) {
        this.show($item);
      } else if (this.options.collapsible) {
        this.hide($item);
      }

      if (!$item.is($prevItem) && $prevItem.length) {
        this.hide($prevItem);
      }

      this.makeCallback('beforeToggle');
    },
    show: function show($item) {
      var $slider = $item.find(this.options.slider);
      $item.addClass(this.options.activeClass);
      $slider.stop().hide().removeClass(accHiddenClass).slideDown({
        duration: this.options.animSpeed,
        complete: function () {
          $slider.removeAttr('style');

          if (this.options.scrollToActiveItem.enable && window.innerWidth <= this.options.scrollToActiveItem.breakpoint) {
            this.goToItem($item);
          }

          this.makeCallback('onShow', $item);
        }.bind(this)
      });
      this.makeCallback('beforeShow', $item);
    },
    hide: function hide($item) {
      var $slider = $item.find(this.options.slider);
      $item.removeClass(this.options.activeClass);
      $slider.stop().show().slideUp({
        duration: this.options.animSpeed,
        complete: function () {
          $slider.addClass(accHiddenClass);
          $slider.removeAttr('style');
          this.makeCallback('onHide', $item);
        }.bind(this)
      });
      this.makeCallback('beforeHide', $item);
    },
    goToItem: function goToItem($item) {
      var itemOffset = $item.offset().top;

      if (itemOffset < $(window).scrollTop()) {
        // handle extra offset
        if (typeof this.options.scrollToActiveItem.extraOffset === 'number') {
          itemOffset -= this.options.scrollToActiveItem.extraOffset;
        } else if (typeof this.options.scrollToActiveItem.extraOffset === 'function') {
          itemOffset -= this.options.scrollToActiveItem.extraOffset();
        }

        $('body, html').animate({
          scrollTop: itemOffset
        }, this.options.scrollToActiveItem.animSpeed);
      }
    },
    getActiveItem: function getActiveItem($item) {
      return $item.siblings().filter('.' + this.options.activeClass);
    },
    makeCallback: function makeCallback(name) {
      if (typeof this.options[name] === 'function') {
        var args = Array.prototype.slice.call(arguments);
        args.shift();
        this.options[name].apply(this, args);
      }
    },
    destroy: function destroy() {
      this.$holder.removeData('SlideAccordion');
      this.$items.off(this.options.event, this.options.opener, this.accordionToggle);
      this.$items.removeClass(this.options.activeClass).each(function (i, item) {
        $(item).find(this.options.slider).removeAttr('style').removeClass(accHiddenClass);
      }.bind(this));
      this.makeCallback('onDestroy');
    }
  };

  $.fn.slideAccordion = function (opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];
    return this.each(function () {
      var $holder = jQuery(this);
      var instance = $holder.data('SlideAccordion');

      if (_typeof(opt) === 'object' || typeof opt === 'undefined') {
        new SlideAccordion($.extend(true, {
          holder: this
        }, opt));
      } else if (typeof method === 'string' && instance) {
        if (typeof instance[method] === 'function') {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };

  (function () {
    var tabStyleSheet = $('<style type="text/css">')[0];
    var tabStyleRule = '.' + accHiddenClass;
    tabStyleRule += '{position:absolute !important;left:-9999px !important;top:-9999px !important;display:block !important; width: 100% !important;}';

    if (tabStyleSheet.styleSheet) {
      tabStyleSheet.styleSheet.cssText = tabStyleRule;
    } else {
      tabStyleSheet.appendChild(document.createTextNode(tabStyleRule));
    }

    $('head').append(tabStyleSheet);
  })();

  return SlideAccordion;
});
/*
 * Responsive Layout helper
 */


window.ResponsiveHelper = function ($) {
  // init variables
  var handlers = [],
      prevWinWidth,
      win = $(window),
      nativeMatchMedia = false; // detect match media support

  if (window.matchMedia) {
    if (window.Window && window.matchMedia === Window.prototype.matchMedia) {
      nativeMatchMedia = true;
    } else if (window.matchMedia.toString().indexOf('native') > -1) {
      nativeMatchMedia = true;
    }
  } // prepare resize handler


  function resizeHandler() {
    var winWidth = win.width();

    if (winWidth !== prevWinWidth) {
      prevWinWidth = winWidth; // loop through range groups

      $.each(handlers, function (index, rangeObject) {
        // disable current active area if needed
        $.each(rangeObject.data, function (property, item) {
          if (item.currentActive && !matchRange(item.range[0], item.range[1])) {
            item.currentActive = false;

            if (typeof item.disableCallback === 'function') {
              item.disableCallback();
            }
          }
        }); // enable areas that match current width

        $.each(rangeObject.data, function (property, item) {
          if (!item.currentActive && matchRange(item.range[0], item.range[1])) {
            // make callback
            item.currentActive = true;

            if (typeof item.enableCallback === 'function') {
              item.enableCallback();
            }
          }
        });
      });
    }
  }

  win.bind('load resize orientationchange', resizeHandler); // test range

  function matchRange(r1, r2) {
    var mediaQueryString = '';

    if (r1 > 0) {
      mediaQueryString += '(min-width: ' + r1 + 'px)';
    }

    if (r2 < Infinity) {
      mediaQueryString += (mediaQueryString ? ' and ' : '') + '(max-width: ' + r2 + 'px)';
    }

    return matchQuery(mediaQueryString, r1, r2);
  } // media query function


  function matchQuery(query, r1, r2) {
    if (window.matchMedia && nativeMatchMedia) {
      return matchMedia(query).matches;
    } else if (window.styleMedia) {
      return styleMedia.matchMedium(query);
    } else if (window.media) {
      return media.matchMedium(query);
    } else {
      return prevWinWidth >= r1 && prevWinWidth <= r2;
    }
  } // range parser


  function parseRange(rangeStr) {
    var rangeData = rangeStr.split('..');
    var x1 = parseInt(rangeData[0], 10) || -Infinity;
    var x2 = parseInt(rangeData[1], 10) || Infinity;
    return [x1, x2].sort(function (a, b) {
      return a - b;
    });
  } // export public functions


  return {
    addRange: function addRange(ranges) {
      // parse data and add items to collection
      var result = {
        data: {}
      };
      $.each(ranges, function (property, data) {
        result.data[property] = {
          range: parseRange(property),
          enableCallback: data.on,
          disableCallback: data.off
        };
      });
      handlers.push(result); // call resizeHandler to recalculate all events

      prevWinWidth = null;
      resizeHandler();
    }
  };
}(jQuery);