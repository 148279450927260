jQuery(function () {
  initMapPopup();
});

function initMapPopup() {
  var map = jQuery('#map');
  jQuery('#map .pin').on('click', function () {
    var pin = jQuery(this);
    var popupData = pin.data('popup');
    var popup = jQuery('div[data-popup="' + popupData + '"]');
    var popupWidth = popup.outerWidth();
    var popupHeight = popup.outerHeight();
    var mapOffsetTop = map.offset().top;
    var mapOffsetLeft = map.offset().left;
    var relativePos = {};
    jQuery('.pin').removeClass('active');
    jQuery('.popup').removeClass('active');
    jQuery('.popup').removeClass('right');
    popup.addClass('active');
    jQuery(this).addClass('active');
    relativePos.top = pin.offset().top - mapOffsetTop - popupHeight / 2;
    relativePos.left = pin.offset().left - mapOffsetLeft - popupWidth;
    popup.css({
      top: relativePos.top,
      left: relativePos.left
    });

    if (popup.offset().left < jQuery(window).scrollLeft()) {
      popup.addClass('right');
      console.log(pin.offset().left);
      relativePos.left = pin.offset().left - mapOffsetLeft;
      popup.css({
        top: relativePos.top,
        left: relativePos.left
      });
    }
  });
  jQuery(window).on('resize', function () {
    if (jQuery('.popup').hasClass('active')) {
      jQuery('.pin').removeClass('active');
      jQuery('.popup').removeClass('active');
      jQuery('.popup').removeAttr('style');
    }
  });
}