jQuery(function () {
  initStickyHeader();
});

function initStickyHeader() {
  var header = jQuery('.header');
  var headerHeight = jQuery('.header').outerHeight();
  var wrapper = jQuery('#wrapper');

  if (jQuery(window).scrollTop() >= headerHeight) {
    header.addClass('fixed');
  } else {
    header.removeClass('fixed');
  }
}

jQuery(window).on('resize scroll', function () {
  initStickyHeader();
});