import counterUp from 'counterup2';
import waypoint from '../libs/waypoint/noframework.waypoints.min';

if (jQuery('.infographics__box').length) {
  jQuery('.infographics__box').each(function () {
    var box = jQuery(this);
    var circle = box.find('.circle');
    var percent = box.find('.infographics__box-num strong').text();
    var dasharray = percent + ',' + 100;
    document.querySelectorAll('.infographics__box-num').forEach(function (el) {
      new Waypoint({
        element: el,
        handler: function handler() {
          el.parentElement.classList.add('in-viewport');
          circle.attr('stroke-dasharray', dasharray);
          counterUp(el, {
            duration: 1500
          });
          this.destroy();
        },
        offset: '98%'
      });
    });
  });
}