function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

jQuery(function () {
  initOpenClose();
}); // open-close init

function initOpenClose() {
  // jQuery('.program__list').openClose({
  //     activeClass: 'active',
  //     opener: '.program__list-opener',
  //     slider: '.program__list-slide',
  //     animSpeed: 400,
  //     effect: 'slide'
  // });
  jQuery('.news-listing__filter').openClose({
    activeClass: 'active',
    opener: '.news-listing__filter-opener',
    slider: '.news-listing__filter-list',
    animSpeed: 400,
    effect: 'slide'
  });
  ResponsiveHelper.addRange({
    '..1023': {
      on: function on() {
        jQuery('.program__category').openClose({
          activeClass: 'program-active',
          opener: '.program__category-opener',
          slider: '.program__hold',
          animSpeed: 400,
          effect: 'slide'
        });
      },
      off: function off() {
        jQuery('.program__category').openClose('destroy');
      }
    }
  });
}
/*
 * jQuery Open/Close plugin
 */


;

(function ($) {
  function OpenClose(options) {
    this.options = $.extend({
      addClassBeforeAnimation: true,
      hideOnClickOutside: false,
      activeClass: 'active',
      opener: '.opener',
      slider: '.slide',
      animSpeed: 400,
      effect: 'fade',
      event: 'click'
    }, options);
    this.init();
  }

  OpenClose.prototype = {
    init: function init() {
      if (this.options.holder) {
        this.findElements();
        this.attachEvents();
        this.makeCallback('onInit', this);
      }
    },
    findElements: function findElements() {
      this.holder = $(this.options.holder);
      this.opener = this.holder.find(this.options.opener);
      this.slider = this.holder.find(this.options.slider);
    },
    attachEvents: function attachEvents() {
      // add handler
      var self = this;

      this.eventHandler = function (e) {
        e.preventDefault();

        if (self.slider.hasClass(slideHiddenClass)) {
          self.showSlide();
        } else {
          self.hideSlide();
        }
      };

      self.slider.attr('aria-hidden', 'true');
      self.opener.attr('aria-expanded', 'false');
      self.opener.on(self.options.event, this.eventHandler); // hover mode handler

      if (self.options.event === 'hover') {
        self.opener.on('mouseenter', function () {
          if (!self.holder.hasClass(self.options.activeClass)) {
            self.showSlide();
          }
        });
        self.holder.on('mouseleave', function () {
          self.hideSlide();
        });
      } // outside click handler


      self.outsideClickHandler = function (e) {
        if (self.options.hideOnClickOutside) {
          var target = $(e.target);

          if (!target.is(self.holder) && !target.closest(self.holder).length) {
            self.hideSlide();
          }
        }
      }; // set initial styles


      if (this.holder.hasClass(this.options.activeClass)) {
        $(document).on('click touchstart', self.outsideClickHandler);
      } else {
        this.slider.addClass(slideHiddenClass);
      }
    },
    showSlide: function showSlide() {
      var self = this;

      if (self.options.addClassBeforeAnimation) {
        self.holder.addClass(self.options.activeClass);
      }

      self.slider.attr('aria-hidden', 'false');
      self.opener.attr('aria-expanded', 'true');
      self.slider.removeClass(slideHiddenClass);
      $(document).on('click touchstart', self.outsideClickHandler);
      self.makeCallback('animStart', true);
      toggleEffects[self.options.effect].show({
        box: self.slider,
        speed: self.options.animSpeed,
        complete: function complete() {
          if (!self.options.addClassBeforeAnimation) {
            self.holder.addClass(self.options.activeClass);
          }

          self.makeCallback('animEnd', true);
        }
      });
      self.makeCallback('onChange', self);
    },
    hideSlide: function hideSlide() {
      var self = this;

      if (self.options.addClassBeforeAnimation) {
        self.holder.removeClass(self.options.activeClass);
      }

      $(document).off('click touchstart', self.outsideClickHandler);
      self.makeCallback('animStart', false);
      toggleEffects[self.options.effect].hide({
        box: self.slider,
        speed: self.options.animSpeed,
        complete: function complete() {
          if (!self.options.addClassBeforeAnimation) {
            self.holder.removeClass(self.options.activeClass);
          }

          self.slider.addClass(slideHiddenClass);
          self.slider.attr('aria-hidden', 'true');
          self.opener.attr('aria-expanded', 'false');
          self.makeCallback('animEnd', false);
        }
      });
    },
    destroy: function destroy() {
      this.slider.removeClass(slideHiddenClass).css({
        display: ''
      });
      this.opener.off(this.options.event, this.eventHandler);
      this.holder.removeClass(this.options.activeClass).removeData('OpenClose');
      $(document).off('click touchstart', this.outsideClickHandler);
    },
    makeCallback: function makeCallback(name) {
      if (typeof this.options[name] === 'function') {
        var args = Array.prototype.slice.call(arguments);
        args.shift();
        this.options[name].apply(this, args);
      }
    }
  }; // add stylesheet for slide on DOMReady

  var slideHiddenClass = 'js-slide-hidden';

  (function () {
    var tabStyleSheet = $('<style type="text/css">')[0];
    var tabStyleRule = '.' + slideHiddenClass;
    tabStyleRule += '{position:absolute !important;left:-9999px !important;top:-9999px !important;display:block !important}';

    if (tabStyleSheet.styleSheet) {
      tabStyleSheet.styleSheet.cssText = tabStyleRule;
    } else {
      tabStyleSheet.appendChild(document.createTextNode(tabStyleRule));
    }

    $('head').append(tabStyleSheet);
  })(); // animation effects


  var toggleEffects = {
    slide: {
      show: function show(o) {
        o.box.stop(true).hide().slideDown(o.speed, o.complete);
      },
      hide: function hide(o) {
        o.box.stop(true).slideUp(o.speed, o.complete);
      }
    },
    fade: {
      show: function show(o) {
        o.box.stop(true).hide().fadeIn(o.speed, o.complete);
      },
      hide: function hide(o) {
        o.box.stop(true).fadeOut(o.speed, o.complete);
      }
    },
    none: {
      show: function show(o) {
        o.box.hide().show(0, o.complete);
      },
      hide: function hide(o) {
        o.box.hide(0, o.complete);
      }
    }
  }; // jQuery plugin interface

  $.fn.openClose = function (opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];
    return this.each(function () {
      var $holder = jQuery(this);
      var instance = $holder.data('OpenClose');

      if (_typeof(opt) === 'object' || typeof opt === 'undefined') {
        $holder.data('OpenClose', new OpenClose($.extend({
          holder: this
        }, opt)));
      } else if (typeof method === 'string' && instance) {
        if (typeof instance[method] === 'function') {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };
})(jQuery);
/*
 * Responsive Layout helper
 */


window.ResponsiveHelper = function ($) {
  // init variables
  var handlers = [],
      prevWinWidth,
      win = $(window),
      nativeMatchMedia = false; // detect match media support

  if (window.matchMedia) {
    if (window.Window && window.matchMedia === Window.prototype.matchMedia) {
      nativeMatchMedia = true;
    } else if (window.matchMedia.toString().indexOf('native') > -1) {
      nativeMatchMedia = true;
    }
  } // prepare resize handler


  function resizeHandler() {
    var winWidth = win.width();

    if (winWidth !== prevWinWidth) {
      prevWinWidth = winWidth; // loop through range groups

      $.each(handlers, function (index, rangeObject) {
        // disable current active area if needed
        $.each(rangeObject.data, function (property, item) {
          if (item.currentActive && !matchRange(item.range[0], item.range[1])) {
            item.currentActive = false;

            if (typeof item.disableCallback === 'function') {
              item.disableCallback();
            }
          }
        }); // enable areas that match current width

        $.each(rangeObject.data, function (property, item) {
          if (!item.currentActive && matchRange(item.range[0], item.range[1])) {
            // make callback
            item.currentActive = true;

            if (typeof item.enableCallback === 'function') {
              item.enableCallback();
            }
          }
        });
      });
    }
  }

  win.bind('load resize orientationchange', resizeHandler); // test range

  function matchRange(r1, r2) {
    var mediaQueryString = '';

    if (r1 > 0) {
      mediaQueryString += '(min-width: ' + r1 + 'px)';
    }

    if (r2 < Infinity) {
      mediaQueryString += (mediaQueryString ? ' and ' : '') + '(max-width: ' + r2 + 'px)';
    }

    return matchQuery(mediaQueryString, r1, r2);
  } // media query function


  function matchQuery(query, r1, r2) {
    if (window.matchMedia && nativeMatchMedia) {
      return matchMedia(query).matches;
    } else if (window.styleMedia) {
      return styleMedia.matchMedium(query);
    } else if (window.media) {
      return media.matchMedium(query);
    } else {
      return prevWinWidth >= r1 && prevWinWidth <= r2;
    }
  } // range parser


  function parseRange(rangeStr) {
    var rangeData = rangeStr.split('..');
    var x1 = parseInt(rangeData[0], 10) || -Infinity;
    var x2 = parseInt(rangeData[1], 10) || Infinity;
    return [x1, x2].sort(function (a, b) {
      return a - b;
    });
  } // export public functions


  return {
    addRange: function addRange(ranges) {
      // parse data and add items to collection
      var result = {
        data: {}
      };
      $.each(ranges, function (property, data) {
        result.data[property] = {
          range: parseRange(property),
          enableCallback: data.on,
          disableCallback: data.off
        };
      });
      handlers.push(result); // call resizeHandler to recalculate all events

      prevWinWidth = null;
      resizeHandler();
    }
  };
}(jQuery);