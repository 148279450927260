jQuery(function () {
  initFilters();
});

function initFilters() {
  jQuery('.programs__row select').change(function () {
    var filters = $.map($('.programs__row select').toArray(), function (e) {
      return $(e).val();
    }).join('.');
    jQuery('.cards .row').find('>div').hide();
    jQuery('.cards .row').find('>div.' + filters).show();

    if (jQuery('.programs .col:visible').length === 0) {
      jQuery('.programs .cards').addClass('no-result');
    } else {
      jQuery('.programs .cards').removeClass('no-result');
    }
  });
  jQuery('select#level').on('change', function () {
    var selectedEventType = jQuery(this).find(':selected').data('value');

    if (selectedEventType == "all") {
      jQuery('select#licensure option').removeClass('hidden');
    } else {
      jQuery('select#licensure option').addClass('hidden');
      jQuery('select#licensure option[data-value="' + selectedEventType + '"]').removeClass('hidden');
    }

    jQuery('select#licensure option[data-value="all"]').removeClass('hidden');
  });
  jQuery('select#licensure').on('change', function () {
    var selectedEventType = jQuery(this).find(':selected').data('value');

    if (selectedEventType == "all") {
      jQuery('select#level option').removeClass('hidden');
    } else {
      jQuery('select#level option').addClass('hidden');
      jQuery('select#level option[data-value="' + selectedEventType + '"]').removeClass('hidden');
    }

    jQuery('select#level option[data-value="all"]').removeClass('hidden');
  }); // jQuery('.programs__clear-link').on('click', function (e) {
  //     e.preventDefault();
  //     jQuery('.cards .row').find('.col').show();
  //     jQuery('.programs select').each(function () {
  //         let select = jQuery(this);
  //         select.prop('selectedIndex', 0);
  //         jQuery('select option').removeClass('hidden');
  //     })
  // })
}