function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

jQuery(function () {
  initMobileNav();
}); // mobile menu init

function initMobileNav() {
  jQuery('body').mobileNav({
    menuActiveClass: 'nav-active',
    menuOpener: '.nav-opener'
  });
  jQuery('body').mobileNav({
    menuActiveClass: 'search-active',
    menuOpener: '.search-opener',
    hideOnClickOutside: true,
    menuDrop: '.header__search'
  });
}
/*
 * Simple Mobile Navigation
 */


;

(function ($) {
  function MobileNav(options) {
    this.options = $.extend({
      container: null,
      hideOnClickOutside: false,
      menuActiveClass: 'nav-active',
      menuOpener: '.nav-opener',
      menuDrop: '.nav-drop',
      toggleEvent: 'click',
      outsideClickEvent: 'click touchstart pointerdown MSPointerDown'
    }, options);
    this.initStructure();
    this.attachEvents();
  }

  MobileNav.prototype = {
    initStructure: function initStructure() {
      this.page = $('html');
      this.container = $(this.options.container);
      this.opener = this.container.find(this.options.menuOpener);
      this.drop = this.container.find(this.options.menuDrop);
    },
    attachEvents: function attachEvents() {
      var self = this;

      if (activateResizeHandler) {
        activateResizeHandler();
        activateResizeHandler = null;
      }

      this.outsideClickHandler = function (e) {
        if (self.isOpened()) {
          var target = $(e.target);

          if (!target.closest(self.opener).length && !target.closest(self.drop).length) {
            self.hide();
          }
        }
      };

      this.openerClickHandler = function (e) {
        e.preventDefault();
        self.toggle();
      };

      this.opener.on(this.options.toggleEvent, this.openerClickHandler);
    },
    isOpened: function isOpened() {
      return this.container.hasClass(this.options.menuActiveClass);
    },
    show: function show() {
      this.container.addClass(this.options.menuActiveClass);

      if (this.options.hideOnClickOutside) {
        this.page.on(this.options.outsideClickEvent, this.outsideClickHandler);
      }
    },
    hide: function hide() {
      this.container.removeClass(this.options.menuActiveClass);

      if (this.options.hideOnClickOutside) {
        this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
      }
    },
    toggle: function toggle() {
      if (this.isOpened()) {
        this.hide();
      } else {
        this.show();
      }
    },
    destroy: function destroy() {
      this.container.removeClass(this.options.menuActiveClass);
      this.opener.off(this.options.toggleEvent, this.clickHandler);
      this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
    }
  };

  var activateResizeHandler = function activateResizeHandler() {
    var win = $(window),
        doc = $('html'),
        resizeClass = 'resize-active',
        flag,
        timer;

    var removeClassHandler = function removeClassHandler() {
      flag = false;
      doc.removeClass(resizeClass);
    };

    var resizeHandler = function resizeHandler() {
      if (!flag) {
        flag = true;
        doc.addClass(resizeClass);
      }

      clearTimeout(timer);
      timer = setTimeout(removeClassHandler, 500);
    };

    win.on('resize orientationchange', resizeHandler);
  };

  $.fn.mobileNav = function (opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];
    return this.each(function () {
      var $container = jQuery(this);
      var instance = $container.data('MobileNav');

      if (_typeof(opt) === 'object' || typeof opt === 'undefined') {
        $container.data('MobileNav', new MobileNav($.extend({
          container: this
        }, opt)));
      } else if (typeof method === 'string' && instance) {
        if (typeof instance[method] === 'function') {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };
})(jQuery);