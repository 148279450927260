'use strict';
/**
 *  Load modules
 */

import accordion from './modules/accordion.js';
import burgerMenu from './modules/burgerMenu.js';
import filter from './modules/filter.js';
import customSelect from './modules/customSelect.js';
import counterUp from './modules/counterUp.js';
import stickyHeader from './modules/stickyHeader.js';
import tabs from './modules/tabs.js';
import anchorLinks from './modules/anchorLinks.js';
import blockInViewport from './modules/blockInViewport.js';
import datepicker from './modules/datepicker.js';
import mapPopup from './modules/mapPopup.js';
import dataTables from './modules/dataTables.js';
import stickyAbout from './modules/stickyAbout.js';
import openClose from './modules/openClose.js';
import perfectScrollbar from './modules/perfectScrollbar.js';
import '../scss/app.scss';
jQuery(document).ready(function () {
  if (jQuery('.banner').length) {
    jQuery('#wrapper').addClass('has-banner');
  }
});
window.addEventListener('load', function () {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./sw.js').then(function (registration) {
      console.log('Service worker successfully registered', registration);
    })["catch"](function (error) {
      console.log('Service worker registration failed', error);
    });
  }
});